import React, { useEffect, useState } from "react";
import { apiCall } from "../util/Api";
import { Grid, Header } from 'semantic-ui-react'
import moment from "moment";
export default function ItemDetails(props) {


    const { mapView, isbn } = props;


    const [results, setResults] = useState(false);
    const [loading, setLoading] = useState(true)
   

    const fetchItem = () => {

        apiCall("tbm/title/getItem", {
            api_key: "12sC3vdedypUpGC3HDt4AK9F19c6QHbz",
            isbn: isbn,
            store_id: 298
        }, (_status, _results) => {
            if (_status) {
                setResults(_results);
            } 
            setLoading(false)
        })
    }

    useEffect(fetchItem, []);

    const parceAuthors = (_authors) => {
        if (!_authors) { return "" }
        return _authors.map((_author, index) => {
            return (<span key={index}>{_author.name}{(index !== _authors.length - 1) ? ", " : ""} </span>);
        });
    }

    if (mapView || loading) {
        return (<></>)
    }


    if (!results) {
        return (

            <div style={{ "height": "147px", "backgroundColor": "#f7f7f7", "display" : "flex", "alignItems" : "center", "justifyContent" : "center" }}>

                <Header style={{"textAlign" : "center"}} size="small" as='h2'>
                    <div className="shim" />
                    Not Found
                    <div className="shim" />
                    <Header.Subheader>
                      No data found for ISBN: <strong>{isbn}</strong>
                    </Header.Subheader>
                </Header>

            </div>
        )
    }


    


    return (
        <>
            <div style={{ "padding": "15px", "paddingBottom": "10px", "backgroundColor": "#f7f7f7", "height": "147px", "overflow": "hidden" }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <img onError={(e) => {
                                e.target.src = "https://bookmanager.com/i/nocover.png"
                            }} style={{ "boxShadow": "0px 5px 10px 0px rgba(0,0,0,0.2)", "maxWidth": "77px" }} src={'https://bookmanager.com/mosaic/i/m?b=' + results.eisbn} />
                        </Grid.Column>
                        <Grid.Column width={12}>

                            <Header size="small" as='h2'>
                                <div className="shim" />
                                {results.title}
                                <Header.Subheader>
                                    {results.subtitle}
                                </Header.Subheader>
                                <div className="shim" />
                                <Header.Subheader>
                                    <em>by: {parceAuthors(results.authors)}</em>
                                </Header.Subheader>
                                <div className="shim" />
                                <div className="shim" />
                                <Header.Subheader>
                                    <strong>{results.binding}</strong>
                                </Header.Subheader>

                                {(results.release_date && <Header.Subheader>
                                    {(results.is_forthcoming) ? "Releases: " : "Published: "} {moment(results.release_date * 1000).format("MMMM D, YYYY")}
                                </Header.Subheader>)}
                                
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </>
    )
}