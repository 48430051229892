import React, { useState, useEffect } from "react";
import { Grid, Button, Input, Divider, Dropdown, Header } from 'semantic-ui-react'
import { apiCall } from "../util/Api";
export default function LocationSelector(props) {

    const { stores, setStores, range, setRange, mapView, isbn = "", setSelected, location } = props;
    const [postalCode, setPostalCode] = useState("");
    const [postalValid, setPostalValid] = useState((location.latitude) ? true : false);
    const [loading, setLoading] = useState(true)

    const fetchStores = () => {


        let obj = {
            api_key: "12sC3vdedypUpGC3HDt4AK9F19c6QHbz",
            isbn: isbn,
            distance_km: range,
        }

        // If postal code, use postal code
        if (postalCode) {
            obj.postal = postalCode;
        } else {
            // if no postal code, use geo 
            if (location.latitude && location.longitude) {
                obj.latitude = location.latitude;
                obj.longitude = location.longitude;
            } else {
                // not enough data to request, set to empty
                setStores({ rows: [] })
                setLoading(false);
                return; 
            }

        }

        apiCall("tbm/nearbyStores/get", obj, (_status, _results) => {


            if (_status) {

                setStores(_results);
                setLoading(false);

                if (postalCode) {
                    setSelected(_results.rows[0]);
                }

            } else {
                setStores({ rows: [] })
                setLoading(false);
            }
        })
    }

    const ranges = [
        { key: '25', value: '25', text: '25km' },
        { key: '50', value: '50', text: '50km' },
        { key: '100', value: '100', text: '100km' },
        { key: '300', value: '300', text: '300km' }
    ]

    useEffect(fetchStores, [range, postalCode]);



    const onhandLabel = "In stock";
    const onorderLabel = "On order";
    const unknownLabel = "";
    const preorderLabel = "Pre-order";

    const drawStatus = (_item) => {

        let { url, onhand = null, onorder = null, is_preorder = null } = _item;


        // Pre-Order
        if (is_preorder) {
            return (<span style={{ "color": "orange", "float": "right", "padding": "1px 3px" }}><small>{preorderLabel}</small></span>)
        }

        // In stock
        if (onhand) {
            return (<span style={{ "color": "#419f4a", "float": "right", "padding": "1px 3px" }}><small>{onhandLabel}</small></span>)
        }

        // On Order
        if (!onhand && onorder) {
            return (<span style={{ "color": "orange", "float": "right", "padding": "1px 3px" }}><small>{onorderLabel}</small></span>)
        }

        // No inventory
        if (!onhand && !onorder && isbn && url !== "") {
            return (<span style={{ "color": "#3b6c94", "float": "right", "padding": "1px 3px" }}><small>{unknownLabel}</small></span>)
        }

        return (<></>)

    }



    const drawItems = () => {


        if (stores.rows.length < 1) {
            return (<>
                <br /><br />
                <Header style={{ "textAlign": "center" }} size="small" as='h2'>
                    <div className="shim" />
                    No stores found
                    <Header.Subheader>
                        No stores found in your location.
                    </Header.Subheader>
                </Header>
                <br /><br /><br />

            </>)
        }


        return stores.rows.map((item, index) => {
            return drawItem(item, index);
        })
    }

    const visitSite = (_url) => {
        window.open(_url, "_blank");
    }




    const validatePostalCode = (_code) => {

        let str = _code.replace(/[\W_ ]+/g, "");
        var ca = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);


        if (ca.test(str.toString())) {
            if (str.length >= 6) {
                if (str.length === 6) {
                    console.log(str)
                   
                    if (postalCode !== str) {
                        setPostalCode(str);
                    }

                    if (!postalValid) {
                        setPostalValid(true);
                       
                        return;
                    }
                } else {
                    if (postalValid) {
                        setPostalValid(false);
                        return;
                    }
                }
                return;
            }
        } else {
            if (str.length >= 6) {
                if (postalValid) {
                    setPostalValid(false);
                    return;
                }
            }
        }

        if (str.length < 6) {
            if (!postalValid) {
                setPostalValid(true);
            }
        }

        // if (str.length === 6) {
        //     if (postalValid) {
        //         setPostalValid(false);
        //         return;
        //     }
        // } else {
        //     if (!postalValid) {
        //         setPostalValid(true);
        //         return;
        //     }
        // }

        // if (str.length === 0) {
        //     if (postalCode !== str) {
        //         console.log("called")
        //         setPostalCode(str);
        //     }
        // }


    }

    const postalFocus = (_code) => {
        setPostalValid(true)
    }

    const test = (_item) => {

        setSelected(_item);
    }

    const drawItem = (_item, _index) => {

        return (

            <div onClick={() => test(_item)} className="locationItem" key={_index}>

                <div style={{ "padding": "14px" }}>

                    <Header size="tiny" as='h2'>

                        <div style={{ "float": "right", "fontWeight": "normal" }}>
                            {drawStatus(_item)}
                        </div>
                        <div style={{ "maxWidth": "280px" }}>
                            {_item.name} -<span style={{ color: "#49af14" }}> {_item.distance_km}km</span>
                        </div>
                        <div className="shim" />
                        <Header.Subheader>
                            {_item.address}
                        </Header.Subheader>
                        <div className="shim" />
                        <Header.Subheader>
                            {_item.city}, {_item.province}
                        </Header.Subheader>
                        <Header.Subheader>
                            <div className="shim" />
                            <strong><a href={"tel:" + _item.phone}>{_item.phone} &nbsp;</a></strong>
                            <div style={{ "float": "right" }}>
                                <Button onClick={() => visitSite(_item.url)} style={{ "padding": "4px 10px", "textAlign": "right", "marginTop": "auto" }} primary size="mini" content='Visit' />
                            </div>
                        </Header.Subheader>
                    </Header>
                </div>



                <Divider style={{ "margin": "0px", "opacity": "0.3" }} />
            </div>
        )

    }


    if (mapView || loading) {
        return (<></>)
    }



    return (
        <>




            <div style={{ "padding": "14px", backgroundColor: "#ddd", "boxShadow": "inset 0px 0px 2px 0px rgba(0,0,0,0.1)" }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Input maxLength="7" class="inputpadding" error={(!postalValid)} onChange={(e, { value }) => validatePostalCode(value)} style={{ "width": "100%", "fontSize": "16px", "padding": "0px" }} placeholder="Enter postal code" />
                        </Grid.Column>
                        <Grid.Column style={{ "width": "80px" }} width={4}>
                            <div style={{ "float": "right" }}><Dropdown selection onChange={(e, { name, value }) => setRange(value)} defaultValue={range} compact placeholder={range.toString() + "km"} options={ranges} /></div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>





            <div className="itemsWrapper" style={{ "display": (mapView) ? "none" : "block" }}>


                {drawItems()}


                {/* Bookingham Palace Bookstore - 91km
            Piccadilly Mall 225 1151 10 Avenue SW

            250-832-3948

            in stock */}

            </div>

        </>
    )
}