

const apiPath = "https://api.bookmanager.com/"; 

export const apiCall  = (_methodName, _args, _callback, _link=false) =>{

        var form_data = new FormData();
       
        if(_args){
            for ( var key in _args ) {
                form_data.append(key, _args[key]);
            }
        }

        if(_link){
            const linkData = [...form_data.entries()];
            const asString = linkData
                .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
                .join('&');
                _callback(true, asString);
               
            return; 
        }

        fetch((apiPath+_methodName), { method: 'POST', body:form_data})
        .then(response => response.json())
        .then((r) => {
            // request is formatted, but there is an error
            if(r.error){
                // is error
                _callback(false, r)
            } else {
                // is success
                _callback("success", r)
            }
        }).catch((e)=>{
            _callback(false, e)
            // is error
        });

}
