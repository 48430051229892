import React from "react";
import { Sticky, Button, Icon } from 'semantic-ui-react'
import credit from "../media/credit.png"
export default function AppHeader(props) {

    const { mapView, setMapView } = props;

    return (
        <>
            
                <div className="appheader" style={{ "backgroundColor": "#fff", "zIndex" : 10000 }}>
                    <div style={{ "padding": "12px 12px", "overflow": "hidden", "height": "50px", "boxShadow": "0 1px 3px -0px rgba(0,0,0,0.1)", "position": "relative" }}>
                        <img style={{ "maxWidth": "120px" }} src="https://bookmanager.com/public/api/btns/Shop Local Text.svg" />
                        <div style={{ "float": "right", "cursor": "pointer" }} >
                            <a href="https://bookmanager.com/" target="_blank"><img style={{"width" : "142px"}} src={credit} /></a>
                        </div>
                    </div>

                    <div className="show-block" style={{ "textAlign": "center", "padding": "0px", "borderBottom": "1px solid #eee", "boxShadow": "0 1px 3px -0px rgba(0,0,0,0.1)" }}>
                        <Button.Group widths={2} >
                            <Button className="noradius" onClick={() => setMapView(false)} basic={(mapView)} primary={(!mapView)} color='blue' active><Icon name='list' /> Details view</Button>
                            <Button className="noradius" onClick={() => setMapView(true)} basic={(!mapView)} primary={(mapView)} color='blue'><Icon name='map outline' /> Map view</Button>
                        </Button.Group>
                    </div>
                </div>
                <div style={{"height" : "50px", "display" : "block"}} />
                <div className="show-block" style={{"height" : "37px"}} />
            
        </>
    )
}