import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StoreMap from "../components/StoreMap";
import AppHeader from "../components/AppHeader";
import ItemDetails from "../components/ItemDetails";
import { Dimmer, Loader } from 'semantic-ui-react'
import LocationSelector from "../components/LocationSelector";
export default function DefaultLayout() {

    // Default location is bookmanager office in kelowna
    const [location, setLocation] = useState({ "latitude":null, "longitude": null });
    const [loading, setLoading] = useState(true);

    // Request location from the browser 
    const getLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                setLoading(false)
            }, () => {
                setLoading(false);
            });
        } else {
                setLoading(false);
        }
    }

    useEffect(getLocation, []);


    // Parent vars shared between StoreMap and LocationSelector
    let { isbn = "" } = useParams();
    isbn = isbn.replace(/[^a-zA-Z0-9]/g, "");
    const [stores, setStores] = useState({ rows: [] })
    const [range, setRange] = useState(25);
    const [mapView, setMapView] = useState(false);
    const [selected, setSelected] = useState(false)

    // Show nothing while loading
    if (loading) {
        return (<>

            <Dimmer inverted active>
                <img style={{ "maxWidth": "200px", "marginTop" : "-100px", "position" : "absolute", "left" : "calc(50% - 100px)" }} src="https://bookmanager.com/public/api/btns/Shop Local Text.svg" />
                <Loader inverted><br />Fetching location...</Loader>
            </Dimmer>

        </>)
    }

    // Draw container + pass vars / methods to components
    return (
        <>
            <div className="wrap" >
                <AppHeader mapView={mapView} setMapView={setMapView} />
                <ItemDetails isbn={isbn} mapView={mapView} />
                <LocationSelector location={location} setSelected={setSelected} isbn={isbn} mapView={mapView} setMapView={setMapView} range={range} setRange={setRange} stores={stores} setStores={setStores} />
            </div>
            <StoreMap location={location} isbn={isbn} selected={selected} mapView={mapView} range={range} stores={stores}></StoreMap>
        </>
    )
}