import React from "react";
import { BrowserRouter as Router, Switch, Route, useParams, useLocation } from "react-router-dom";

import ItemDetails from "./components/ItemDetails";
import LocationSelector from "./components/LocationSelector";
import DefaultLayout from "./layouts/DefaultLayout";
import SearchLayout from "./layouts/SearchLayout";
export default function SiteRouter() {



    return (
        <Switch>

            <Route path="/isbn/:isbn">
                <DefaultLayout />
            </Route>
            <Route exact path="*">
                <></>
            </Route>
        </Switch>
    )
}