import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import { BrowserRouter as Router, Switch, Route, useParams, useLocation } from "react-router-dom";

import SiteRouter from './SiteRouter';


function App() {




  return (
    <>
      <Router basename="/">
        <SiteRouter />
      </Router>
    </>
  );
}

export default App;
